














































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import moment from 'moment';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import WrapperContent from '@/components/WrapperContent/WrapperContent.vue';
import ExerciseContainer from '@/components/Exercise/ExerciseContainer/ExerciseContainer.vue';
import ExerciseContainerLoading from '@/components/Exercise/ExerciseContainer/ExerciseContainerLoading.vue';
import MapQuestions from '@/components/Exercise/MapQuestions/MapQuestions.vue';
import Question from '@/components/Exercise/Question/Question.vue';
import RadioAlternative from '@/components/Exercise/RadioAlternative/RadioAlternative.vue';
import NavigationQuestion from '@/components/Exercise/NavigationQuestion/NavigationQuestion.vue';
import OptionsContent from '@/components/OptionsContent/OptionsContent.vue';
import Footer from '@/components/Footer/Footer.vue';
import ModalTimeFinishQuestion from '@/components/Exercise/ExerciseContainer/Modals/ModalTimeFinishQuestion.vue';
import ModalFinishQuestion from '@/components/Exercise/ExerciseContainer/Modals/ModalFinishQuestion.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ExerciseService from '@/services/Exercise/ExerciseService';
import HistoricService from '@/services/Historic/historicService';
import BookService from '@/services/Book/BookService';

import ActiveModal from '@/share/Util/ActiveModal';
import ScrollControl from '@/share/Util/ScrollControl/ScrollControl';
import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import { Book } from '@/globalInterfaces/Book';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    WrapperContent,
    ExerciseContainer,
    ExerciseContainerLoading,
    MapQuestions,
    Question,
    RadioAlternative,
    NavigationQuestion,
    OptionsContent,
    Footer,
    ModalTimeFinishQuestion,
    ModalFinishQuestion,
    FeedbackUser,
  },
})
export default class ExerciseQuestions extends Vue {
  private all = false;
  private isAnimation = false;
  private checked = false;
  private isLoading = true;

  private message = '';

  private currentNumberQuestion = 0;

  private countDown = 0;
  private totalTime = 0;
  private clock = '';
  private timer = 0;

  private exercise: Record<string, any> = {}
  private options: Record<string, any> = {}
  private questions: Array<Record<string, any>> = []
  private currentQuestion: Record<string, any> = {};

  private ExerciseService = new ExerciseService();
  private HistoricService = new HistoricService();
  private BookService = new BookService();

  private setModal = new ActiveModal();
  private setScroll = new ScrollControl();

  async created() {
    await this.verifyResponseExercise();
    await this.getExercise();
    await this.isChecked();
    this.setCurrentQuestion();
  }

  mounted() {
    this.setAnimation();
    this.setBreadCrumbs();
  }

  beforeDestroy() {
    this.clearIntervalTimer();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get getContextExercise() {
    const { context, path } = this.$route.params;
    if (context === 'planner') return context;

    if (context === 'cursos') return context;

    return path;
  }

  get getIdTopic() {
    return Number(this.$route.params.topicID);
  }

  get getIdFile() {
    return Number(this.$route.params.fileID);
  }

  get lastQuestion() {
    return this.questions?.length === this.currentNumberQuestion + 1;

  }

  get firstQuestion() {
    return this.currentNumberQuestion === 0;

  }

  get isShowAllQuestion() {
    return this.options.howToShow === 'all';
  }

  async getExercise() {
    try {
      this.isLoading = true;

      const { fileID } = this.$route.params;

      this.exercise = await this.ExerciseService.getExercise(Number(fileID));

      this.options = this.ExerciseService.getOptions();

      this.all = this.options.howToShow !== 'one';

      this.questions = this.ExerciseService.shuffleQuestions(this.exercise, this.options.qtdQuestions);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o exercício.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async verifyResponseExercise() {
    try {
      const { context, path, fileID } = this.$route.params;

      const response = await this.ExerciseService.getExerciseByUser(Number(fileID));

      if (response && Object.entries(response).length) {
        if (context === 'planner') {
          this.$router.push({
            path: '/planner',
          });

          return;
        }

        if (context === 'cursos') {
          const splitPath = path.split('&');
          const slugCourse = splitPath[0];
          const idCourses = splitPath[1];
          const idClass = splitPath[2];

          this.$router.push({
            path: `/cursos/${slugCourse}/${idCourses}/${idClass}`,
          });

          return;
        }

        this.$router.push({
          path: `${context}/${path}`,
        });
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a resposta do exercício.',
        status: 'error',
      });

      console.error(error);
    }
  }

  setCurrentQuestion() {
    this.currentQuestion = this.questions[this.currentNumberQuestion];
  }

  mapNavQuestion(value: number): void {
    if (this.currentNumberQuestion === value) {
      return;
    }

    this.setTrackAmplitude({
      previousQuestionNumber: this.currentNumberQuestion + 1,
      currentQuestionNumber: value + 1,
    });

    this.setAnimation();
    setTimeout(() => {
      this.currentNumberQuestion = value;
      this.currentQuestion = this.questions[this.currentNumberQuestion];
    }, 500);

    if (this.isShowAllQuestion) this.setScroll.scrollToElement(`container-${value}`, { block: 'center' });
  }

  nextQuestion(): void {
    this.setTrackAmplitude({
      local: 'Botão - Próxima',
    });

    if (this.currentNumberQuestion >= this.questions.length - 1) {
      return;
    }
    this.setAnimation();
    setTimeout(() => {
      this.currentNumberQuestion += 1;
      this.currentQuestion = this.questions[this.currentNumberQuestion];
    }, 500);
  }

  previousQuestion(): void {
    this.setTrackAmplitude({
      local: 'Botão - Anterior',
    });

    if (this.currentNumberQuestion <= 0) {
      return;
    }
    this.setAnimation();
    setTimeout(() => {
      this.currentNumberQuestion -= 1;
      this.currentQuestion = this.questions[this.currentNumberQuestion];
    }, 500);
  }

  setRadio(currentIndex: number, id: number, indexQuestion: number): void {
    if (this.all === true) {
      this.questions[indexQuestion].options.forEach((alternativeItem: Record<string, string | boolean>, indexAlternative: number) => {
        if (indexAlternative === currentIndex) {
          this.questions[indexQuestion].options[currentIndex].value = true;
          this.questions[indexQuestion].response = 'answered';
        } else {
          this.questions[indexQuestion].options[indexAlternative].value = false;
        }
      });
      this.currentNumberQuestion = indexQuestion;
    } else {
      this.questions[this.currentNumberQuestion].options.forEach((item: Record<string, string | boolean>, index: number) => {
        if (index === currentIndex) {
          this.questions[this.currentNumberQuestion].options[index].value = true;
          this.setResponse('answered');
        } else {
          this.questions[this.currentNumberQuestion].options[index].value = false;
        }
      });
    }
  }

  setTextarea(response: string, indexQuestion: number): void {
    if (this.all === true) {
      this.questions[indexQuestion].response = response;
      if (response) {
        this.questions[indexQuestion].response = 'answered';
        this.exercise.currentNumberQuestion = indexQuestion;
        return;
      }
      this.questions[indexQuestion].response = 'skip';
    } else {
      this.questions[this.currentNumberQuestion].response = response;
      if (response) {
        this.setResponse('answered');
        return;
      }
      this.setResponse('skip');
    }
  }

  setResponse(value: string): void {
    this.questions[this.currentNumberQuestion].registry_date = new Date().getTime();
    this.questions[this.currentNumberQuestion].response = value;
  }

  handleClickBtnFinish() {
    this.setTrackAmplitude({
      local: 'Botão - Finalizar',
    });

    this.$store.commit('setExerciseResult', this.questions);

    this.exerciseIsOver();
  }

  async resultExercise() {
    try {
      const {
        context, path, topicID, fileID, slugFile,
      } = this.$route.params;

      if (context === 'planner') {
        // Fazer a logica do planner
        // precisa pegar o id da materia e colocar na segunda no 3º argumento da função de salvar
      }

      if (context === 'cursos') {
        await this.ExerciseService.saveExercise(Number(fileID), this.questions, Number(topicID), (this.totalTime - this.countDown));
      } else {
        const book = await this.getBookData();

        if (!book) return;

        await this.ExerciseService.saveExercise(Number(fileID), this.questions, book?.order, (this.totalTime - this.countDown));
      }

      await this.setCheckbox('exercice', true);

      this.$router.push({ path: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}/resultado` });
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar o exercício.',
        status: 'error',
      });
      console.error(error);
    }
  }

  async getBookData() {
    const { path } = this.$route.params;
    const allBooksStore: Book[] = this.$store.getters.allBooks;

    const bookFromStore = this.findBookSlug(allBooksStore, path);

    if (bookFromStore) return bookFromStore;

    const allBooksService: Book[] = await this.BookService.books(undefined, this.$store.getters.profile?.institution?.id);

    return this.findBookSlug(allBooksService, path);
  }

  findBookSlug(books: Book[], slug: string) {
    if (!books.length) return null;

    return books.find((book: Book) => (book.slug === slug));
  }

  exerciseIsOver() {
    this.$store.getters.exerciseResult.forEach((question: Record<string, any>) => {
      if (question.response === 'skip' || question.response === '') {
        this.message = 'Ainda existem questões não respondidas, deseja finalizar o exercício?';
      } else {
        this.message = 'Parabéns, todas as questões estão respondidas. Quer finalizar o exercício agora?';
      }
    });
  }

  setAnimation() {
    this.isAnimation = true;
    setTimeout(() => {
      this.isAnimation = false;
    }, 1200);
  }

  async isChecked() {
    this.checked = await this.HistoricService.getHistoric({ type: 'exercice', listIds: [this.exercise.idExercice] }) || [];
  }

  async setCheckbox(type: string, checked: boolean) {
    await this.HistoricService.saveHistoric({
      idobject: Number(this.$route.params.fileID),
      type,
      checked,
    });
  }

  setCountDown() {
    this.timer = setInterval(() => {
      this.countDown -= 1;

      if (this.countDown <= 0) {
        clearInterval(this.timer);
      }

      const formatted = moment.utc(this.countDown * 1000).format('HH:mm:ss');
      this.clock = formatted;
    }, 1000);
  }

  @Watch('options')
  setCount() {
    if (this.options) {
      this.countDown = Number(this.options.time) * 60 * Number(this.options.qtdQuestions);
      this.totalTime = Number(this.options.time) * 60 * Number(this.options.qtdQuestions);
      this.setCountDown();
    }
  }

  @Watch('countDown')
  finishTime() {
    if (this.countDown === 0) {
      this.setModal.activeModal('modalTimeFinishQuestion');
    }
  }

  @Watch('this.$route.path')
  clearIntervalTimer() {
    clearInterval(this.timer);
  }

  async setBreadCrumbs() {
    const {
      context, path, topicID, fileID, slugFile,
    } = this.$route.params;

    if (context === 'planner') {
      this.setBreadCrumbsPlanner();
      return;
    }

    if (context === 'cursos') {
      this.setBreadCrumbsCourse();
      return;
    }

    const bookTitle = await this.getBookData();

    this.$breadcrumb.set([
      { title: 'cadernos', to: '/cadernos' },
      { title: bookTitle?.title, to: `/caderno/${path}` },
      { title: 'exercício', to: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}` },
      { title: 'questões', to: null },
    ]);
  }

  setBreadCrumbsPlanner() {
    const {
      context, path, topicID, fileID, slugFile,
    } = this.$route.params;

    this.$breadcrumb.set([
      { title: 'planner', to: '/planner' },
      { title: 'exercício', to: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}` },
      { title: 'questões', to: null },
    ]);
  }

  setBreadCrumbsCourse() {
    const {
      context, path, topicID, fileID, slugFile,
    } = this.$route.params;

    const splitPath = path.split('&');
    const slugCourse = splitPath[0];
    const idCourses = splitPath[1];
    const idClass = splitPath[2];

    this.$breadcrumb.set([
      { title: 'cursos', to: `/cursos/${slugCourse}/${idCourses}/${idClass}` },
      { title: 'exercício', to: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}` },
      { title: 'questões', to: null },
    ]);
  }

  setTrackAmplitude(value: object) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ExerciseQuestions',
          ...value,
        },
      },
    });
  }
}
