




























































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import MapQuestionLoading from '../MapQuestions/MapQuestionLoading.vue';

@Component({
  components: {
    ExLoading,
    MapQuestionLoading,
  },
})
export default class ExerciseContainerLoading extends Vue {
  private quantityLoadingQuestion = 3;
  private quantityLoading = 4;

}
